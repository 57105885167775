/*
|--------------------------------------------------------------------------
| Base URLs (Donde se encuentra la API)
|--------------------------------------------------------------------------
| URL local
| http://api.steknos.local/
|
| URL clickowebs
| https://apisteknos.clickowebs.com/
|
| URL produccion
| https://api.steknos.es/
*/
export const baseUrl = 'https://api.steknos.es/'

export const getDefaultState = () => {
    return {
        listEquipments: {
            page: 1,
            pageLength: 9,
            totalRecords: 10,
        }
    }
}

export default getDefaultState()
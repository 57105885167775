import state from './listState'
import actions from './listActions'
import mutations from './listMutations'
import getters from './listGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
